@import url(https://fonts.googleapis.com/css?family=Noto+Sans:700|Roboto:400,700&display=swap);

/* Typography */

#june-jung{
  font-size: 1.75rem;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 700;
}

#nav-menu-layout{
  font-size: 1.25rem;
}

#nav-hamburger{
  font-size: 2rem;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 700;
}

h1,h2,h3,h4{
  font-family: 'Noto Sans', sans-serif;
  font-weight: 700;
}

p{
  font-size: 1rem;
}

body{
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

#landing-layout .title{
  font-family: 'Noto Sans';
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
}

.website-links{
  font-size: 1rem;
  font-weight: 700;
}

/* Base Styling */
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
  color: #000000;
}

img{
  width: 100%;
  height: auto;
}

.projects-container img{
  border: 1px solid #b8b8b8;
  border-radius: 5px;
}

/* Mobile First*/
/* Header Layout */
#header-layout{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

#jj-logo{
  height: 2.5rem;
  width:  2.5rem;
}

#june-jung{
  margin: auto;
  padding-right: 40px;
}

.MuiIconButton-label{
  color: #666666;
}

#header-layout .MuiSvgIcon-root{
  width: 2rem !important;
  height: 2rem !important;
}

#nav-social-hamburger .MuiSvgIcon-root{
  width: 2.5rem !important;
  height: 2.5rem !important;
}

#nav-menu-layout{
  display:none;
}

#drawer-layout{
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: calc(100vh - 50px);
  text-align: center;
}

#nav-hamburger{
  width: 100vw;
  padding-top: 2rem;
}

#nav-hamburger li{
  padding: 1rem 0 1rem 0;
}

#nav-social{
  display: none;
}

#drawer-close-bttn{
  text-align: right;
}

#nav-social-hamburger{
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 1rem;
}

/* Footer (sticky) Layout */
#main-layout{
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
}

#footer-layout{
  padding-top: 2rem;
  padding-bottom: .5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: auto;
  text-align: center;
}

/* Landing Layout */
#landing-intro{
  padding-bottom: 4rem;
}

#landing h1{
  margin: 0 auto;
  padding: 0 1rem 0 1rem;
  max-width: 400px;
  text-align: left;
}

#landing-layout{
  display: flex;
  flex-flow: column nowrap;
  padding: 0 .5rem 0 .5rem;
}

#landing-layout img{
  max-width: 400px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  margin-bottom: .75rem;
}

#landing-layout>div{
  margin-bottom: 2.5rem;
}

#landing-layout>div>a{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

/* Projects Layout */
.projects-container h1{
  text-align: center;
  padding-bottom: 2rem;
  margin-top: 2rem;
}

.projects-container h2{
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.projects-container{
  max-width: 1000px;
  margin: .5rem;
  padding-bottom: 2rem;
}

.big-img{
  max-width: 1000px;
  margin-bottom: 1rem;
}

.textbox{
  margin: 0 auto;
  max-width: 750px;
}

.divider{
  margin: 2rem 0 1rem 0 !important;
}

.mobile-wireframe{
  margin: 0 auto;
}

.website-links{
  display:flex;
  align-items: center;
  padding-top: 1rem;
}

.website-links svg{
  padding-right: .25rem;
}

/* (VPL & Streetfood) */
.current-app-screen, .app-wireframe{
  max-width: 1000px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

/* (React Dev Website) */
#devreact-layout .code-img{
  max-width: 750px;
}

#devreact-layout .react-img{
  max-width: 1200px;
}

#devreact-layout .textbox{
  padding-bottom: 2rem;
}

/* (Gover) */
#gover-layout img {
  max-width: 1000px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

#role-container, #persona-container, #problem-solution{
  display: flex;
  flex-flow: column nowrap;
}

#role-container div, #persona-container>div, #problem-solution>div{
  background-color: #fff;
  border: solid 1px #b8b8b8;
  border-radius: 5px;

  padding: 0 .4rem 1rem .5rem;
  margin-bottom: 1.5rem;
}

#persona-container>div div:nth-of-type(1), #persona-container>div div:nth-of-type(2){
  border-bottom: 1px solid #b8b8b8;
}

#gover-layout .mobile-wireframe img, #gover-layout .mobile-mockup img{
  max-width: 400px;
}

#gover-layout h3 {
  padding-top: .5rem;
}

/* About Layout */
#about-intro{
  padding-bottom: 4rem;
}

#about-layout h1{
  margin: auto;
  padding: 1rem 1rem 1.5rem 1rem;
  max-width: 600px;
  text-align: center;
}

#about-intro>p{
  margin: 0 auto;
  padding: 0 1rem 0 1rem;
  max-width: 400px;
}

#about-tools>h1{
  margin: 0 auto;
  max-width: 90%;
  border-top: 1px solid #b8b8b8;
}

#tools-layout{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

#tools-layout>div{
  padding: 0 1rem 0 1rem;
}

#design-layout div:first-of-type{
  padding-bottom: 1rem;
}

/* Contact Layout */
#contact-layout{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

form{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  min-width: 80vw;
  margin-top: 1.5rem;
}

#contact-button{
  min-width: 80vw;
  margin-top: 2rem;
}

/* Responsive */
@media (min-width: 420px) {
  /* Projects Layout */
  /* (VPL & Streetfood) */
  .current-app-screen div, .app-wireframe div{
    width: 320px;
  }

  /* WTC & Massybooks */
  #wtc-layout .mobile-wireframe, #massybooks-layout .mobile-wireframe{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  #wtc-layout .mobile-wireframe div, #massybooks-layout .mobile-wireframe div{
    width: 320px;
  }

  /* Gover */
  #gover-layout .mobile-wireframe, #gover-layout .mobile-mockup{
    text-align: center;
  }

  /* About Layout */
  #tools-layout{
    justify-content: space-around;
  }

  /* Contact Layout */
  form{
    margin-top: 2rem;
  }

}

@media (min-width: 750px) {
  /* Header Layout */
  #nav-menu-layout{
    display: flex;
    flex-flow: row nowrap;
  }

  #nav-menu-layout li:first-of-type, #nav-menu-layout li:nth-of-type(2){
    padding-right: 2rem;
  }

  #nav-hamburger-button{
    display: none;
  }

  #nav-social{
    display: block;
    margin-left: 151px;
  }

  #june-jung{
    padding-right: 0;
  }

  /* Landing Layout */
  #landing h1{
    max-width: 600px;
    text-align: center;
  }

  /* Project Layout */
  .projects-container{
    margin: 0 auto;
    padding: .5rem;
    padding-bottom: 2rem;
  }

  /* (Gover) */
  #role-container, #persona-container, #problem-solution{
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  #persona-container>div{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
  }



  #role-container div, #persona-container div{
    max-width: 240px;
  }

  #problem-solution div{
    max-width: 360px;
  }

  #gover-layout .mobile-wireframe, #gover-layout .mobile-mockup{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }

  #gover-layout .mobile-wireframe img, #gover-layout .mobile-mockup img{
    max-width: 300px;
  }

  /* About Layout */
  #design-layout{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
  }

  #design-layout div:first-of-type{
    padding-right: 2.5rem;
  }

  #about-intro>p{
    max-width: 600px;
  }

  #dev-layout{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
  }
  
  #dev-layout>div:first-of-type{
    padding-right: 4rem;
  }

  /* Contact Layout */
  form{
    min-width: 500px;
    max-width: 500px;
  }
  
  #contact-button{
    min-width: 500px;
    max-width: 500px;
  }
}

@media (min-width: 850px) {
  /* Landing Layout */
  #landing-layout{
    flex-flow: row wrap;
    justify-content: space-evenly;
    max-width: 1590px;
    margin: 0 auto;
  }

  /* Projects */
  /* WTC & Massybooks */
  .projects-secondary-container{
    flex-flow: row nowrap;
  }

  .projects-secondary-container .web-wireframe{
    margin-right: 1rem;
  }

  /* About Layout */
  #about-tools>h1{
    max-width: 800px;
  }
}

@media (min-width: 1100px) {
  /* About Layout */
    #tools-layout{
      max-width: 900px;
      margin: 0 auto;
    }
  }
